<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.uuid" @change="Search" placeholder="uuid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.product_model_code" @change="Search" placeholder="型号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.sn" @change="Search" placeholder="设备sn"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.status" @change="Search" placeholder="设备状态">
            <el-option label="已激活" :value="2"></el-option>
            <el-option label="待激活" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addcontent">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="uuid">
        <template v-slot="scope">
          <span>{{ scope.row.uuid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号编号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出厂mac信息">
        <template v-slot="scope">
          <span>{{ scope.row.mac }}</span>
        </template>
      </el-table-column>
      <el-table-column label="sn信息">
        <template v-slot="scope">
          <span>{{ scope.row.sn }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <span style="color:red" v-if="scope.row.status==1">待激活</span>
          <span style="color:green" v-else>已激活</span>
        </template>
      </el-table-column>
      <el-table-column label="设备来源">
        <template v-slot="scope">
          <span>{{ scope.row.brand }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editDelivery(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteDelivery(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'设备出厂'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="产品型号编号" prop="product_model_code" label-width="110px">
          <el-select v-model="form.product_model_code">
            <el-option
              v-for="item in promodel"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备唯一编号" prop="uuid" label-width="110px">
          <el-input v-model="form.uuid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备秘钥" prop="uuid_secret" label-width="110px">
          <el-input v-model="form.uuid_secret" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出厂mac信息" prop="mac" label-width="110px">
          <el-input v-model="form.mac" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="sn信息" prop="sn" label-width="110px">
          <el-input v-model="form.sn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status" label-width="110px">
          <el-select v-model="form.status" clearable placeholder="请选择">
            <el-option label="待激活" :value="1"></el-option>
            <el-option label="已激活" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备来源" prop="brand" label-width="110px">
          <el-input v-model="form.brand" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        uuid: '',
        product_model_code: '',
        sn: '',
        status: '',
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        id: '',
        product_model_code: '',
        uuid: '',
        uuid_secret: '',
        mac: '',
        sn: '',
        status: '',
        brand: '',
      },
      rules: {
        product_model_code: [{ required: true, message: '请输入产品型号编号', trigger: 'blur' }],
        uuid: [{ required: true, message: '请输入设备唯一编号', trigger: 'blur' }],
        uuid_secret: [{ required: true, message: '请输入设备秘钥', trigger: 'blur' }],
        mac: [{ required: true, message: '请输入出厂mac信息', trigger: 'blur' }],
        sn: [{ required: true, message: '请输入出厂sn信息', trigger: 'blur' }],
        status: [{ required: true, message: '请输入状态', trigger: 'blur' }],
        brand: [{ required: true, message: '请输入设备来源', trigger: 'blur' }],
      },
      promodel: [],
      modelcodelist: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    GetPromodel () {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.promodel = res.data.data.data
          res.data.data.data.forEach(item => {
            this.modelcodelist[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search(){
      this.api.DeliveryList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcontent () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editDelivery (item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.DeliveryDetail({id:item.id}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm(){
      this.update()
    },
    update(){
      this.api.DeliveryUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
    deleteDelivery(item) {
      this.$confirm('是否删除该设备出厂记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.DeliveryDelete({id: item}).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.GetPromodel()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
